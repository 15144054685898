import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));

// let URL = process.env.REACT_APP_API_BASE_URL;
let URL = process.env.REACT_APP_API_BASE_URL;
switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
        URL = process.env.REACT_APP_API_BASE_URL;
        break;
    case 'production':
        URL = process.env.REACT_APP_PROD_BASE_URL;
        break;
    default:
        URL = process.env.REACT_APP_API_BASE_URL;
        break;
}
const instance = axios.create({
    baseURL: URL
});

if (user?.token) {
    // eslint-disable-next-line dot-notation
    instance.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
}

instance.defaults.headers.common['Content-Type'] = 'application/json';

export function setAuthTokens(token) {
    // eslint-disable-next-line dot-notation
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response?.status === 401) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            window.location.replace('/login');
        } else if (error.response && error.response?.status === 403) {
            if (window) {
                // eslint-disable-next-line
                window.alert('Access Forbidden, Tell your administrator for more information !!');
            }
        }
        return Promise.reject(error);
    }
);

export default instance;

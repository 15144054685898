import moment from 'moment';
import useUser from 'hooks/useUser';
import useDebounce from 'hooks/useDebounce';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { SNACKBAR_OPEN } from 'store/actions';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
    Autocomplete,
    Box,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers';
import { Close } from '@mui/icons-material';

import MainCard from 'ui-component/cards/MainCard';
import EnhancedTableHead from 'ui-component/datatable/EnchancedTableHead';

import 'views/pages/tunkin/proposal-allowance/style.css';
import styled from 'styled-components';
import EmptyDataIcon from 'assets/images/icons/empty-data.svg';
import { Image } from 'styles/image.style';

import { getPeriode, syncPeriode } from 'services/api/lkpb';

// Table Header Options
const headCells = [
    {
        id: 'nama',
        numeric: false,
        label: 'Nama',
        align: 'center'
    },
    {
        id: 'tahun',
        numeric: false,
        label: 'Tahun',
        align: 'center'
    },
    {
        id: 'periode_awal',
        numeric: false,
        label: 'Periode Awal',
        align: 'center'
    },
    {
        id: 'periode_akhir',
        numeric: false,
        label: 'Periode Akhir',
        align: 'center'
    },
    {
        id: 'batas_pengisian',
        numeric: false,
        label: 'Batas Pengisian',
        align: 'center'
    }
];

// menu button
export const StyledMenu = styled.div`
    width: 100%;
    height: 160px;
    background: #ffffff;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const SKPPeriodePage = () => {
    moment.locale('id');
    const dispatch = useDispatch();
    const theme = useTheme();

    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [selected, setSelected] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalCheck, setModalCheck] = useState(false);
    const [filterDate, setFilterDate] = useState('');
    const [typeDate, setTypeDate] = useState('text');
    const filterTypeData = ['Tunjangan Kinerja', 'PLT'];
    const [filterType, setFilterType] = useState('');
    const [search, setSearch] = useState('');
    const searchData = useDebounce(search, 1000);
    const [notif, setNotif] = useState({
        created: false,
        deleted: false,
        updated: false,
        failed: false
    });

    const { user } = useUser();
    const [payload, setPayload] = useState('');
    // const [formData, setFormData] = useState({
    //     identityNumber: ''
    // });

    const handleSearch = (event) => {
        const newString = event?.target.value;
        setSearch(newString || '');
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotif({
            created: false,
            deleted: false,
            updated: false,
            failed: false
        });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedId = rows.map((n) => n.identityNumber);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        // if (newPage !== 0) {
        setPage(newPage);
        // } else {
        //     setPage(1);
        // }
    };

    const handleChangeRowsPerPage = (event) => {
        if (event?.target.value) setRowsPerPage(parseInt(+event?.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setLoading(true);
        getPeriode({
            pageIndex: page + 1,
            pageSize: rowsPerPage,
            query: searchData,
            sortBy: `${orderBy},${order}`,
            tahun: filterDate
        })
            .then((res) => {
                if (res && res.data.data && res.data.data.length > 0) {
                    // setPage(res.data.meta.page);
                    setRows(res.data.data);
                    setTotalPage(res.data.total);
                } else {
                    setRows([]);
                    setTotalPage(0);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [rowsPerPage, page, searchData, orderBy, order, filterDate, filterType]);

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = page > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;
    const [anchorEl, setAnchorEl] = useState(null);
    const handleButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <MainCard title="LKPB Periode" content={false} className="lkpb-periode">
            <Snackbar
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'top'
                }}
                open={notif.deleted}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Data berhasil dihapus
                </Alert>
            </Snackbar>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography fontWeight={600} fontSize="18px" lineHeight="21px">
                                Cari :
                            </Typography>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearch}
                                placeholder="Search"
                                value={search}
                                size="small"
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" alignItems="center" spacing={1} justifyContent="end">
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {typeDate === 'text' ? (
                                    <TextField
                                        type={typeDate}
                                        size="small"
                                        placeholder="Pilih Periode"
                                        onFocus={() => setTypeDate('month')}
                                    />
                                ) : (
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setTypeDate('text');
                                                setFilterDate(moment('').format('YYYY-MM'));
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                        <DatePicker
                                            label="YYYY-MM"
                                            format="YYYY-MM"
                                            views={['month', 'year']}
                                            value={moment(filterDate)}
                                            onChange={(e) => setFilterDate(moment(e))}
                                            sx={{ width: '190px', fontSize: '14px' }}
                                        />
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>

            {/* Table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        theme={theme}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={totalPage}
                        selected={selected}
                        headCells={headCells}
                        noAction
                        noCheck
                    />
                    <TableBody>
                        {!loading ? (
                            rows &&
                            rows?.map((row, index) => {
                                /** Make sure no display bugs if row isn't an OrderData object */
                                if (typeof row === 'number') return null;
                                const isItemSelected = isSelected(row.nip);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                    >
                                        <TableCell
                                            id={labelId}
                                            scope="row"
                                            align="center"
                                            // onClick={(event) => handleClick(event, row.nip)}
                                            sx={{ cursor: 'pointer', fontSize: '12px' }}
                                        >
                                            {row?.nama}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                                            {row?.tahun}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                                            {row?.periode_awal}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                                            {row?.periode_akhir}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                                            {row?.batas_pengisian}
                                        </TableCell>
                                    </TableRow>
                                    // )
                                );
                            })
                        ) : (
                            <TableRow
                                style={{
                                    height: '50vh'
                                }}
                            >
                                <TableCell colSpan={8} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!loading && rows.length <= 0 && (
                <Stack direction="column" spacing={1} height="50vh" width="100%" alignItems="center" justifyContent="center">
                    <Image src={EmptyDataIcon} />
                    <Typography fontWeight={700}>Data tidak tersedia</Typography>
                </Stack>
            )}

            {/* Table Pagination */}
            <Box sx={{ position: 'relative', padding: '33px 24px 24px 24px', width: '100%' }} />
            <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    component="div"
                    count={totalPage ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ position: 'absolute', bottom: 0, right: 0 }}
                />
            </Box>
        </MainCard>
    );
};

export default SKPPeriodePage;

import { useSelector } from 'react-redux';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
// import { FirebaseProvider } from 'contexts/FirebaseContext';
// import { JWTProvider } from 'contexts/JWTContext';
// import { Auth0Provider } from 'contexts/Auth0Context';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    return (
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        {/* RTL layout */}
                        {/* <RTLLayout> */}
                        <Locales>
                            <NavigationScroll>
                                {/* <FirebaseProvider> */}

                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                                {/* </FirebaseProvider> */}
                            </NavigationScroll>
                        </Locales>
                        {/* </RTLLayout> */}
                    </ThemeProvider>
                </StyledEngineProvider>
            </LocalizationProvider>
        </QueryClientProvider>
    );
};

export default App;

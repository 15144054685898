// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconLayoutDashboard,
    IconSettings,
    IconUserCheck,
    IconBook2,
    IconUser,
    IconNotebook,
    IconMail,
    IconCash,
    IconChecklist,
    IconUsers,
    IconLockOpen,
    IconHelp,
    IconVocabulary,
    IconFiles,
    IconFileDatabase
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconLayoutDashboard,
    IconSettings,
    IconUserCheck,
    IconBook2,
    IconUser,
    IconNotebook,
    IconMail,
    IconCash,
    IconChecklist,
    IconUsers,
    IconLockOpen,
    IconHelp,
    IconVocabulary,
    IconFiles,
    IconFileDatabase
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    // title: "PMS V3",
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconLayoutDashboard,
            breadcrumbs: false,
            roles: [
                'super-admin',
                'admin-satker',
                'admin-biro-sdm',
                'kepala-biro-sdm',
                'sekretariat-jendral',
                'biro-keuangan',
                'fungsional-keuangan',
                'kasubbag-gaji',
                'employee'
            ],
            permissions: [
                'ProposalAllowance@dashboard',
                'ProposalPLT@dashboard',
                'TerminationOfAllowance@dashboard',
                'SalarySubmission@dashboard',
                'DataAllowance@dashboardGrade',
                'DataAllowance@dashboardPosition'
            ]
        },
        {
            id: 'tunkin',
            title: <FormattedMessage id="tunkin" />,
            type: 'collapse',
            icon: icons.IconBook2,
            roles: ['super-admin', 'admin-satker', 'admin-biro-sdm', 'kepala-biro-sdm', 'sekretariat-jendral', 'employee'],
            permissions: [
                'DataAllowance@index',
                'ProposalAllowance@index',
                'TerminationOfAllowance@index',
                'ViolationEmployee@index',
                'DataAllowance@getCalculation',
                'Nominative@index',
                'Employee@get_tunkin'
            ],
            children: [
                {
                    id: 'data-tunkin',
                    title: <FormattedMessage id="data-tunkin" />,
                    type: 'item',
                    permissions: ['DataAllowance@index'],
                    url: '/tunkin/data',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm']
                },
                {
                    id: 'proposal-tunkin',
                    title: <FormattedMessage id="proposal-tunkin" />,
                    type: 'item',
                    permissions: ['ProposalAllowance@index'],
                    url: '/tunkin/proposal',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm', 'kepala-biro-sdm', 'sekretariat-jendral']
                },
                {
                    id: 'stop-tunkin',
                    title: <FormattedMessage id="stop-tunkin" />,
                    type: 'item',
                    permissions: ['TerminationOfAllowance@index'],
                    url: '/tunkin/termination',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm', 'kepala-biro-sdm']
                },
                {
                    id: 'cutting-tunkin',
                    title: <FormattedMessage id="cutting-tunkin" />,
                    type: 'item',
                    permissions: ['ViolationEmployee@index'],
                    url: '/tunkin/cutting',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm']
                },
                {
                    id: 'count-tunkin',
                    title: <FormattedMessage id="count-tunkin" />,
                    type: 'item',
                    permissions: ['DataAllowance@getCalculation', 'Employee@get_tunkin'],
                    url: '/tunkin/count',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm', 'employee']
                },
                {
                    id: 'nominative-list',
                    title: <FormattedMessage id="nominative-list" />,
                    type: 'item',
                    permissions: ['Nominative@index'],
                    url: '/tunkin/nominative-list',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm']
                }
            ]
        },
        {
            id: 'executor',
            title: <FormattedMessage id="executor" />,
            type: 'collapse',
            icon: icons.IconUser,
            roles: ['super-admin', 'admin-satker', 'admin-biro-sdm', 'kepala-biro-sdm', 'sekretariat-jendral'],
            permissions: ['ProposalPLT@index', 'TerminationOfProposalPLT@index'],
            children: [
                {
                    id: 'proposal-executor',
                    title: <FormattedMessage id="proposal-executor" />,
                    type: 'item',
                    permissions: ['ProposalPLT@index'],
                    url: '/plt/proposal',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm', 'kepala-biro-sdm', 'sekretariat-jendral']
                },
                {
                    id: 'stop-executor',
                    title: <FormattedMessage id="stop-executor" />,
                    type: 'item',
                    permissions: ['TerminationOfProposalPLT@index'],
                    url: '/plt/termination',
                    roles: ['super-admin', 'admin-satker', 'admin-biro-sdm']
                }
            ]
        },
        {
            id: 'study',
            title: <FormattedMessage id="study" />,
            type: 'item',
            url: '/tugas-belajar',
            icon: icons.IconVocabulary,
            roles: ['super-admin', 'admin-satker', 'biro-keuangan'],
            permissions: ['ViolationEmployee@index']
        },
        {
            id: 'user-management',
            title: <FormattedMessage id="user-management" />,
            type: 'collapse',
            icon: icons.IconUserCheck,
            roles: ['super-admin'],
            permissions: ['User@index', 'RoleManagement@showRolePaginate'],
            children: [
                {
                    id: 'role-list',
                    title: <FormattedMessage id="role-list" />,
                    type: 'item',
                    url: '/user/role-management',
                    roles: ['super-admin'],
                    permissions: ['User@index']
                },
                {
                    id: 'user-list',
                    title: <FormattedMessage id="user-list" />,
                    type: 'item',
                    url: '/user/user-list',
                    roles: ['super-admin'],
                    permissions: ['RoleManagement@showRolePaginate']
                }
                // {
                //     id: 'permission-list',
                //     title: <FormattedMessage id="permission-list" />,
                //     type: 'item',
                //     url: '/user/permission-list',
                //     roles: ['super-admin']
                // }
            ]
        },
        {
            id: 'employee',
            title: <FormattedMessage id="employee" />,
            type: 'item',
            url: '/employee-list',
            icon: icons.IconUsers,
            roles: ['super-admin', 'biro-keuangan', 'admin-biro-sdm'],
            permissions: ['EmployeeMissmatchProfile@index']
        },
        {
            id: 'presence',
            title: <FormattedMessage id="presence" />,
            type: 'item',
            icon: icons.IconChecklist,
            url: '/presence/list',
            roles: ['super-admin', 'admin-biro-sdm'],
            permissions: ['Presence@index']
            // children: [
            //     {
            //         id: 'presence-list',
            //         title: <FormattedMessage id="presence-list" />,
            //         type: 'item',
            //         url: '/presence/list',
            //         roles: ['super-admin', 'admin-satker', 'kepala-biro-sdm', 'sekretariat-jendral']
            //     },
            //     {
            //         id: 'presence-recap',
            //         title: <FormattedMessage id="presence-recap" />,
            //         type: 'item',
            //         url: '/presence/recap',
            //         roles: ['super-admin', 'admin-satker', 'kepala-biro-sdm', 'sekretariat-jendral']
            //     }
            //     // {
            //     //     id: 'presence-leave',
            //     //     title: <FormattedMessage id="presence-leave" />,
            //     //     type: 'item',
            //     //     url: '/presence/leave',
            //     //     roles: ['super-admin', 'admin-satker', 'biro-keuangan', 'kepala-biro-sdm', 'sekretariat-jendral']
            //     // }
            // ]
        },
        {
            id: 'spmt&spmmj',
            title: <FormattedMessage id="spmt&spmmj" />,
            type: 'item',
            url: '/spmt-spmmj',
            icon: icons.IconMail,
            roles: ['super-admin', 'admin-satker', 'biro-keuangan', 'kepala-biro-sdm', 'sekretariat-jendral'],
            permissions: ['StatementLetter@index', 'Employee@get_smpt', 'Employee@get_spmmj', 'Employee@get_kelasjabatan']
        },
        {
            id: 'salary-detail',
            title: <FormattedMessage id="salary-detail" />,
            type: 'collapse',
            icon: icons.IconCash,
            roles: ['super-admin', 'biro-keuangan', 'kasubbag-gaji', 'fungsional-keuangan'],
            permissions: [
                'SalaryDetails@index',
                'SalarySubmission@index',
                'OverTime@index',
                'MealBill@index',
                'SPMTPLN@index',
                'SPMtunkin@index'
            ],
            children: [
                {
                    id: 'salary-detail',
                    title: <FormattedMessage id="salary-detail-submission" />,
                    permissions: ['SalaryDetails@index'],
                    type: 'item',
                    url: '/salary/detail',
                    roles: ['super-admin', 'biro-keuangan', 'kasubbag-gaji']
                },
                {
                    id: 'salary-submission',
                    title: <FormattedMessage id="salary-submission" />,
                    permissions: ['SalarySubmission@index'],
                    type: 'item',
                    url: '/salary/submission',
                    roles: ['super-admin', 'biro-keuangan', 'fungsional-keuangan']
                },
                {
                    id: 'salary-basic',
                    title: <FormattedMessage id="salary-basic" />,
                    permissions: ['SalaryDetails@index'],
                    type: 'item',
                    url: '/salary/basic',
                    roles: ['super-admin', 'biro-keuangan', 'fungsional-keuangan']
                },
                {
                    id: 'salary-mealallowance',
                    title: <FormattedMessage id="salary-mealallowance" />,
                    permissions: ['MealBill@index'],
                    type: 'item',
                    url: '/salary/meal-allowance',
                    roles: ['super-admin', 'biro-keuangan', 'fungsional-keuangan']
                },
                {
                    id: 'salary-overtime',
                    title: <FormattedMessage id="salary-overtime" />,
                    permissions: ['OverTime@index'],
                    type: 'item',
                    url: '/salary/over-time',
                    roles: ['super-admin', 'biro-keuangan', 'fungsional-keuangan']
                },
                {
                    id: 'salary-tpln',
                    title: <FormattedMessage id="salary-tpln" />,
                    permissions: ['SPMTPLN@index'],
                    type: 'item',
                    url: '/salary/tpln',
                    roles: ['super-admin', 'biro-keuangan', 'fungsional-keuangan']
                },
                {
                    id: 'salary-tunkin',
                    title: <FormattedMessage id="salary-tunkin" />,
                    permissions: ['SPMtunkin@index'],
                    type: 'item',
                    url: '/salary/tunkin',
                    roles: ['super-admin', 'biro-keuangan', 'fungsional-keuangan']
                },
                {
                    id: 'count-pph21',
                    title: <FormattedMessage id="count-pph21" />,
                    permissions: [
                        'SalaryDetails@index',
                        'SalarySubmission@index',
                        'OverTime@index',
                        'MealBill@index',
                        'SPMTPLN@index',
                        'SPMtunkin@index'
                    ],
                    type: 'item',
                    url: '/salary/pph21',
                    roles: ['super-admin', 'biro-keuangan', 'fungsional-keuangan']
                }
            ]
        },
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'collapse',
            icon: icons.IconSettings,
            roles: ['super-admin', 'admin-biro-sdm'],
            permissions: [
                'PerformanceAllowance@store',
                'MealAllowance@store',
                'MasterPLT@store',
                'ViolationType@store',
                'Violation@store',
                'WorkingDay@store',
                'WorkingUnit@store',
                'MasterPosition@store',
                'MasterBank@store',
                'AccountNumber@store',
                'DocumentTemplate@index',
                'LKPB@get_periode'
            ],
            children: [
                {
                    id: 'master-plt',
                    title: <FormattedMessage id="master-plt" />,
                    type: 'item',
                    url: '/setting/master-plt',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['MasterPLT@store']
                },
                // {
                //     id: 'master-uangmakan',
                //     title: <FormattedMessage id="master-uangmakan" />,
                //     type: 'item',
                //     url: '/setting/master-uangmakan',
                //     roles: ['super-admin', 'admin-biro-sdm'],
                //     permissions: ['MealAllowance@store']
                // },
                {
                    id: 'master-tunkin',
                    title: <FormattedMessage id="master-tunkin" />,
                    type: 'item',
                    url: '/setting/master-tunkin',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['PerformanceAllowance@store']
                },
                {
                    id: 'master-working-unit',
                    title: <FormattedMessage id="master-working-unit" />,
                    type: 'item',
                    url: '/setting/master-working-unit',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['WorkingUnit@store']
                },
                {
                    id: 'cutting-type',
                    title: <FormattedMessage id="cutting-type" />,
                    type: 'item',
                    url: '/scission/type',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['ViolationType@store']
                },
                {
                    id: 'cutting-table',
                    title: <FormattedMessage id="cutting-table" />,
                    type: 'item',
                    url: '/scission',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['Violation@store']
                },
                {
                    id: 'working-days',
                    title: <FormattedMessage id="working-days" />,
                    type: 'item',
                    url: '/setting/working-days',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['WorkingDay@store']
                },
                {
                    id: 'master-position',
                    title: <FormattedMessage id="master-position" />,
                    type: 'item',
                    url: '/setting/master-position',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['MasterPosition@store']
                },
                {
                    id: 'master-bank',
                    title: <FormattedMessage id="master-bank" />,
                    type: 'item',
                    url: '/setting/master-bank',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['MasterBank@store']
                },
                {
                    id: 'master-rekening',
                    title: <FormattedMessage id="master-rekening" />,
                    type: 'item',
                    url: '/setting/master-rekening',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['AccountNumber@store']
                },
                {
                    id: 'master-template',
                    title: <FormattedMessage id="master-template" />,
                    type: 'item',
                    url: '/setting/master-template',
                    roles: ['super-admin', 'admin-biro-sdm'],
                    permissions: ['DocumentTemplate@index']
                },
                {
                    id: 'scheduler-setting',
                    title: <FormattedMessage id="scheduler-setting" />,
                    type: 'item',
                    url: '/setting/scheduler-setting',
                    roles: ['super-admin'],
                    permissions: ['SchedulerTask@list']
                },
                {
                    id: 'periode',
                    title: <FormattedMessage id="skp-periode" />,
                    type: 'item',
                    url: '/setting/periode',
                    roles: ['super-admin'],
                    permissions: ['LKPB@get_periode']
                }
            ]
        },
        {
            id: 'change-password',
            title: <FormattedMessage id="change-password" />,
            type: 'item',
            url: '/change-password',
            icon: icons.IconLockOpen,
            roles: [
                'super-admin',
                'admin-satker',
                'admin-biro-sdm',
                'kepala-biro-sdm',
                'sekretariat-jendral',
                'biro-keuangan',
                'fungsional-keuangan',
                'kasubbag-gaji'
            ],
            permissions: ['User@resetPassword']
        },{
            id: 'skp-data',
            title: <FormattedMessage id="skp-data" />,
            type: 'item',
            url: '/skp-data',
            icon: icons.IconFileDatabase,
            roles: [
                'super-admin',
                'admin-satker',
                'admin-biro-sdm',
                'kepala-biro-sdm',
                'sekretariat-jendral',
                'biro-keuangan',
                'fungsional-keuangan',
                'kasubbag-gaji'
            ],
            permissions: ['LKPB@get_all']
        },
        {
            id: 'sppt',
            title: <FormattedMessage id="sppt" />,
            type: 'item',
            url: '/sppt',
            icon: icons.IconFiles,
            roles: [
                'super-admin',
                'admin-satker',
                'admin-biro-sdm',
                'kepala-biro-sdm',
                'sekretariat-jendral',
                'biro-keuangan',
                'fungsional-keuangan',
                'kasubbag-gaji',
                'employee'
            ],
            permissions: ['Employee@get_sppt']
        }

        // {
        //     id: 'analytics',
        //     title: <FormattedMessage id="analytics" />,
        //     type: 'item',
        //     url: '/dashboard/analytics',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;

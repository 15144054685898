import React from 'react';
import { Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import Close from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    position: absolute;
    max-height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    background: #ffffff;
    border-radius: 20px;
    @media only screen and (max-width: 768px) {
        width: 350px;
    }
    ::-webkit-scrollbar {
        display: none;
    }
`;

function ModalExportSKP({ open, success, close, onClose }) {
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Container>
                <Stack padding="10px 10px 0 10px" direction="row" alignItems="end" justifyContent="end">
                    <IconButton color="default" aria-label="close" size="small" onClick={close}>
                        <Close sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </Stack>
                {success ? (
                    <>
                        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
                            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                                <Typography variant="h3">Berhasil di export</Typography>
                                <CheckCircleIcon color="success" />
                            </Stack>
                            <Typography>
                                Data berhasil di-export.
                            </Typography>
                        </Stack>
                        <Stack display="flex" alignItems="end" justifyContent="flex-end" padding="15px 15px">
                            <Button variant="contained" color="primary" onClick={close}>
                                OK
                            </Button>
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
                            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                                <Typography variant="h3">Gagal di export</Typography>
                                <ErrorIcon color="error" />
                            </Stack>
                            <Typography>Data gagal di-export, coba lagi.</Typography>
                        </Stack>
                        <Stack display="flex" alignItems="end" justifyContent="flex-end" padding="15px 15px">
                            <Button variant="contained" color="primary" onClick={close}>
                                Batal
                            </Button>
                        </Stack>
                    </>
                )}
            </Container>
        </Modal>
    );
}

export default ModalExportSKP;

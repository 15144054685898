import axios from 'services/ApiClient';

export const getNotificationPagination = async ({ pageIndex, pageSize, sortBy }) => {
    return await axios.get(`/api/v1/notification/detail`, {
        params: {
            pageIndex,
            pageSize,
            sortBy: 'date,desc'
        }
    });
};

export const changeStatusNotification = async (data) => {
    return await axios.put(`/api/v1/notification`, data);
};

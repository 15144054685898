// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const borderRadius = '12px';

export const currentYear = new Date().getFullYear();

export const storageKey = {
    USER_ID: 'userId',
    USER_TOKEN: 'userToken',
    USER_INFO: 'userInfo',
    USER_ROLE: 'userRole',
    USER_NAME: 'userName',
    USER_ROLE_NAME: 'userRoleName',
    USER_PERMISSION: '',
    IS_LOGGED_ID: 'isLoggedIn'
};

import { Badge, Box, Divider, IconButton, Popover, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Image } from 'styles/image.style';
import moment from 'moment';
import 'moment/locale/id';
import LightTooltip from 'ui-component/tooltip';
import { IconBell, IconBellRinging } from '@tabler/icons';
import { changeStatusNotification, getNotificationPagination } from 'services/api/notification';
import useUser from 'hooks/useUser';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const DateSection = () => {
    moment.locale('id');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notifData, setNotifData] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const { user } = useUser();
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getNotificationPagination({ pageIndex: 1, pageSize: 100 }).then((res) => {
            if (res.data && res.data.data) {
                setNotifData(res.data.data);
                setTotalData(res.data.total);
            } else {
                setNotifData([]);
                setTotalData(0);
            }
        });
    }, []);

    const handleReadNotif = async (ids) => {
        try {
            changeStatusNotification({
                id: ids,
                is_read: true
            }).then((res) => {
                if (res.data) {
                    getNotificationPagination({ pageIndex: 1, pageSize: 100 }).then((res) => {
                        if (res.data) {
                            setNotifData(res.data.data);
                            setTotalData(res.data.total);
                        }
                    });
                }
            });
        } catch (err) {
            console.log(err);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Stack direction="column" spacing={1} mr={1}>
            <Stack direction="row" alignItems="center">
                <LightTooltip title="Notifikasi">
                    <IconButton
                        color="warning"
                        size="large"
                        aria-describedby={id}
                        onClick={handleClick}
                        sx={{
                            marginRight: 2,
                            backgroundColor: 'rgba(255, 229, 127, 0.04)',
                            padding: '2px',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                            '&:hover': {
                                background: '#FFFFFF'
                            }
                        }}
                        end
                    >
                        <Badge color="error" badgeContent={notifData.filter((d) => d.is_read === false).length}>
                            <IconBell sx={{ fontSize: '1.3rem' }} />
                        </Badge>
                    </IconButton>
                </LightTooltip>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            borderRadius: 0
                        }
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            mt: '10px',
                            '&::before': {
                                backgroundColor: '#FFFFFF',
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                width: 12,
                                height: 12,
                                top: -6,
                                transform: 'rotate(45deg)',
                                left: 'calc(50% - 6px)'
                            }
                        }}
                    />

                    <Box
                        sx={{
                            background: '#FFFFFF',
                            // filter: 'blur(1.44703px)',
                            borderRadius: '10px',
                            minWidth: '300px',
                            maxWidth: '392px',
                            maxHeight: '70vh'
                        }}
                    >
                        <Box sx={{ pt: '28px', pb: 0, px: '29px', backgroundColor: '#FFFFFF', borderRadius: '10px 10px 0 0' }}>
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography fontWeight={500}>Notifikasi</Typography>
                                    <Typography fontWeight={500}>{notifData.filter((d) => d.is_read === false).length}</Typography>
                                </Stack>
                                <Divider sx={{ border: '1px solid #000000' }} />
                            </Stack>
                        </Box>
                        {/* <Stack direction="column"> */}
                        {notifData && notifData?.length > 0 ? (
                            notifData.map((row, i) => {
                                return (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        sx={{
                                            background: row.is_read === true ? '#FFFFFF' : 'gainsboro',
                                            cursor: 'pointer',
                                            '&:hover': { background: 'darkgray' },
                                            borderRadius: notifData?.length - 1 !== i ? '0' : '0 0 10px 10px'
                                        }}
                                        key={i}
                                        onClick={() => {
                                            handleReadNotif(row.id);
                                            if (row.type === 'Pengusulan Tunjangan') {
                                                navigate('/tunkin/proposal');
                                            } else if (row.type === 'Pemberhentian Tunjangan') {
                                                navigate('/tunkin/termination');
                                            } else if (row.type === 'Pengusulan Pelaksana Tugas') {
                                                navigate('/plt/proposal');
                                            } else if (row.type === 'Pemberhentian Pelaksana Tugas') {
                                                navigate('/plt/termination');
                                            }
                                        }}
                                        p={2}
                                    >
                                        <Badge variant="dot" color="success" />
                                        <Stack direction="column" spacing={1}>
                                            {/* <Stack direction="row" align="center" justifyContent="space-between" sx={{ px: 2, pt: 2 }}> */}
                                            <Typography fontWeight={700}>{row.type}</Typography>
                                            <Typography>{moment(row.date).fromNow()}</Typography>
                                            {/* </Stack> */}
                                            <Typography>{row.descriptions}</Typography>
                                        </Stack>
                                    </Stack>
                                );
                            })
                        ) : (
                            <Stack direction="column" spacing={1} p={2}>
                                <Typography textAlign="center">Tidak ada data</Typography>
                            </Stack>
                        )}
                        {/* </Stack> */}
                    </Box>
                </Popover>
                <Typography fontFamily="Lato" fontWeight={700} fontSize="16px" lineHeight="21px" mr={1} color="#FFFFFF">
                    {moment().format('dddd')},
                </Typography>
                <Typography fontFamily="Lato" fontWeight={500} fontSize="16px" lineHeight="21px" mr={0.5} color="#FFFFFF">
                    {moment().format('DD')}
                </Typography>
                <Typography fontFamily="Lato" fontWeight={500} fontSize="16px" lineHeight="21px" mr={0.5} color="#FFFFFF">
                    {moment().format('MMM')}
                </Typography>
                <Typography fontFamily="Lato" fontWeight={500} fontSize="16px" lineHeight="21px" mr={0.5} color="#FFFFFF">
                    {moment().format('YY')}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default DateSection;

import { createContext, useState } from 'react';
import { setAuthTokens } from 'services/ApiClient';

export const UserContext = createContext({
    user: null,
    token: null,
    setUser: (value) => {},
    setToken: (value) => {}
});

export const UserProvider = ({ children }) => {
    const initialJSON = localStorage.getItem('user');
    const [user, setUser] = useState(JSON.parse(initialJSON));

    const setUserVoid = (value) => {
        const initialUser = typeof value !== 'string' ? value : JSON.stringify(value)
        // eslint-disable-next-line dot-notation
        localStorage.setItem('user', JSON.stringify(initialUser));
        setAuthTokens(initialUser?.token);
        setUser(initialUser);
    };

    return (
        <UserContext.Provider
            value={{
                user,
                token: user?.token,
                setUser: setUserVoid,
                setToken: (v)=> setUser({
                    ...user,
                    token: v
                })
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const userManagement = {
    id: 'users',
    title: <FormattedMessage id="users" />,
    type: 'group',
    children: [
        {
            id: 'user-management',
            title: <FormattedMessage id="user-management" />,
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'role-list',
                    title: <FormattedMessage id="role-list" />,
                    type: 'item',
                    url: '/user/role-management'
                },
                {
                    id: 'user-list',
                    title: <FormattedMessage id="user-list" />,
                    type: 'item',
                    url: '/user/user-list'
                },
                {
                    id: 'permission-list',
                    title: <FormattedMessage id="permission-list" />,
                    type: 'item',
                    url: '/user/permission-list'
                }
            ]
        }
    ]
};

export default userManagement;

import axios from 'services/ApiClient';

export const getPeriode = async ({ pageIndex, pageSize, sortBy, query, year }) => {
    return await axios.get(`${process.env.REACT_APP_API_V2}/lkpb/periode/list`, {
        params: {
            pageIndex,
            pageSize,
            query,
            year
        }
    });
};

export const syncPeriode = async () => {
    return await axios.get(`${process.env.REACT_APP_API_V2}/lkpb/periode`);
};

export const syncDataLKPB = async ({triwulan, tahun}) => {
    return await axios.get(`${process.env.REACT_APP_API_V2}/lkpb`, {
        params: {
            triwulan,
            tahun
        }
    });
};

export const getLKPB = async ({ pageIndex, pageSize, sortBy, query, tahun }) => {
    return await axios.get(`${process.env.REACT_APP_API_V2}/lkpb/list`, {
        params: {
            pageIndex,
            pageSize,
            query,
            tahun
        }
    });
};

export const getExport = async ({ periode }) => {
    return await axios.get(`${process.env.REACT_APP_API_V2}/lkpb/export`, {
        params: {
            periode
        },
        responseType: 'blob'
    });
};

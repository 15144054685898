import styled, { css } from 'styled-components';

export const Img = styled.img`
    width: 100%;
`;

export const Image = styled.img`
    alt: 'Image';
    object-fit: ${({ fit }) => fit};
    object-position: ${({ objectPos }) => objectPos};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: ${({ borderRadius }) => borderRadius};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    background: ${({ background }) => background};
    z-index: ${({ zIndex }) => zIndex};
    position: 50% 50%;
    src: ${({ src }) => src};
    -webkit-user-drag: none;
    user-select: none;
    -ms-user-select: none;
    -ms-touch-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    ${(p) =>
        p.isPressable &&
        css`
            cursor: pointer;
            &:active {
                opacity: 0.7;
            }
        `}
    &:valid {
        background-color: transparent;
    }
    &:after {
        .conic-gradient:after {
            display: block;
            border-radius: 9999px;
            content: '';
            width: 108%;
            height: 108%;
            position: absolute;
            z-index: -1;
            left: -4%;
            top: -4%;
            background-image: conic-gradient(#00aeef, #fff, #00aeef, #fff, #00aeef);
            animation: spin 5s linear infinite;
        }

        @keyframes spin {
            100% {
                transform: rotate(360deg);
            }
        }
    }
`;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SKPPeriodePage from 'views/pages/lkpb/periode';
import SKPListData from 'views/pages/lkpb';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// setting
const MasterPLTPage = Loadable(lazy(() => import('views/pages/setting/master-plt/index')));
const MasterUangMakanPage = Loadable(lazy(() => import('views/pages/setting/master-uangmakan/index')));
const MasterTunkinPage = Loadable(lazy(() => import('views/pages/setting/master-tunkin/index')));
const MasterWorkingUnitPage = Loadable(lazy(() => import('views/pages/setting/master-working-unit/index')));
const MasterPositionPage = Loadable(lazy(() => import('views/pages/setting/master-position')));
const MasterBankPage = Loadable(lazy(() => import('views/pages/setting/master-bank')));
const MasterRekeningPage = Loadable(lazy(() => import('views/pages/setting/master-rekening/index')));
const MasterTemplatePage = Loadable(lazy(() => import('views/pages/setting/master-template/index')));

// user management
const RoleManegementPage = Loadable(lazy(() => import('views/pages/user-management/role-management/index')));
const CreateRolePage = Loadable(lazy(() => import('views/pages/user-management/role-management/CreateRole')));
const UserListPage = Loadable(lazy(() => import('views/pages/user-management/users/UsersPage')));
const CreateUserPage = Loadable(lazy(() => import('views/pages/user-management/users/CreateUser')));
const PermissionListPage = Loadable(lazy(() => import('views/pages/user-management/permission')));
const CreatePermission = Loadable(lazy(() => import('views/pages/user-management/permission/CreatePermission')));

// scission

const ScissionTypePage = Loadable(lazy(() => import('views/pages/scission/scission-type/index')));
const ScissionTablePage = Loadable(lazy(() => import('views/pages/scission/scission-table/index')));

// allowance
const ProposalAllowancePage = Loadable(lazy(() => import('views/pages/tunkin/proposal-allowance')));
const PreviewProposalAllowance = Loadable(lazy(() => import('views/pages/tunkin/proposal-allowance/preview')));
const TerminationAllowancePage = Loadable(lazy(() => import('views/pages/tunkin/termination-allowance')));
const AllowanceDataPage = Loadable(lazy(() => import('views/pages/tunkin/allowance-data')));
const CuttingAllowancePage = Loadable(lazy(() => import('views/pages/tunkin/cutting-allowance')));
const CountAllowancePage = Loadable(lazy(() => import('views/pages/tunkin/count-allowance')));
const NominativeListPage = Loadable(lazy(() => import('views/pages/tunkin/nominative-list')));

// plt
const ProposalPLTPage = Loadable(lazy(() => import('views/pages/plt/proposal-plt/index')));
const TerminationPLTPage = Loadable(lazy(() => import('views/pages/plt/termination-plt/index')));

// study
const StudyPage = Loadable(lazy(() => import('views/pages/study/index')));

// spmt
const SPMTPage = Loadable(lazy(() => import('views/pages/spmt')));

// employee
const EmployeePage = Loadable(lazy(() => import('views/pages/employee')));

// presence
const PresencePage = Loadable(lazy(() => import('views/pages/presence/list')));
const PresenceRecapPage = Loadable(lazy(() => import('views/pages/presence/recap')));
const PresenceLeavePage = Loadable(lazy(() => import('views/pages/presence/leave')));

// salary detail
const SalaryDetailPage = Loadable(lazy(() => import('views/pages/salary-detail/index')));
const SalarySubmissionPage = Loadable(lazy(() => import('views/pages/salary-detail/submission')));
const SPMBasicPage = Loadable(lazy(() => import('views/pages/salary-detail/spm-basic')));
const SPMMealAllowancePage = Loadable(lazy(() => import('views/pages/salary-detail/spm-mealallowance')));
const SPMOverTimePage = Loadable(lazy(() => import('views/pages/salary-detail/spm-overtime')));
const SPMTPLNPage = Loadable(lazy(() => import('views/pages/salary-detail/spm-tpln')));
const SPMTunkinPage = Loadable(lazy(() => import('views/pages/salary-detail/spm-tunkin')));
const CountPPh21Page = Loadable(lazy(() => import('views/pages/salary-detail/count-pph21')));

// working days

const WorkingDaysPage = Loadable(lazy(() => import('views/pages/setting/working-days')));
const WorkingDaysDetailPage = Loadable(lazy(() => import('views/pages/setting/working-days-detail')));

// spmt
const ChangePasswordPage = Loadable(lazy(() => import('views/pages/change-password')));

// faq
const FaqPage = Loadable(lazy(() => import('views/pages/faq')));
const SchedulerPage = Loadable(lazy(() => import('views/pages/setting/scheduler-setting')));

// ==============================|| MAIN ROUTING ||============================== //

// const token = localStorage.getItem('token');

const MainRoutes = {
    path: '/',
    element: (
        // <AuthGuard>
        <MainLayout />
        // </AuthGuard>
    ),
    children: [
        {
            path: '/setting/master-plt',
            element: <MasterPLTPage />
        },
        {
            path: '/setting/master-uangmakan',
            element: <MasterUangMakanPage />
        },
        {
            path: '/setting/master-tunkin',
            element: <MasterTunkinPage />
        },
        {
            path: '/setting/master-working-unit',
            element: <MasterWorkingUnitPage />
        },
        {
            path: '/setting/working-days',
            element: <WorkingDaysPage />
        },
        {
            path: '/setting/working-days-detail',
            element: <WorkingDaysDetailPage />
        },
        {
            path: '/setting/master-position',
            element: <MasterPositionPage />
        },
        {
            path: '/setting/master-bank',
            element: <MasterBankPage />
        },
        {
            path: '/setting/master-rekening',
            element: <MasterRekeningPage />
        },
        {
            path: '/setting/master-template',
            element: <MasterTemplatePage />
        },
        {
            path: '/user/role-management',
            element: <RoleManegementPage />
        },
        {
            path: '/user/role-management/create',
            element: <CreateRolePage />
        },
        {
            path: '/user/role-management/edit/:id',
            element: <CreateRolePage />
        },
        {
            path: '/user/user-list',
            element: <UserListPage />
        },
        {
            path: '/user/user-list/create',
            element: <CreateUserPage />
        },
        {
            path: '/user/user-list/edit/:id',
            element: <CreateUserPage />
        },
        {
            path: '/user/permission-list',
            element: <PermissionListPage />
        },
        {
            path: '/user/permission-list/create',
            element: <CreatePermission />
        },
        {
            path: '/user/permission-list/edit/:id',
            element: <CreatePermission />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/scission',
            element: <ScissionTablePage />
        },
        {
            path: '/scission/type',
            element: <ScissionTypePage />
        },
        {
            path: '/tunkin/proposal',
            element: <ProposalAllowancePage />
        },
        {
            path: '/tunkin/proposal/preview/:id',
            element: <PreviewProposalAllowance />
        },
        {
            path: '/tunkin/termination',
            element: <TerminationAllowancePage />
        },
        {
            path: '/tunkin/data',
            element: <AllowanceDataPage />
        },
        {
            path: '/tunkin/cutting',
            element: <CuttingAllowancePage />
        },
        {
            path: '/tunkin/count',
            element: <CountAllowancePage />
        },
        {
            path: '/tunkin/nominative-list',
            element: <NominativeListPage />
        },
        {
            path: '/plt/proposal',
            element: <ProposalPLTPage />
        },
        {
            path: '/plt/termination',
            element: <TerminationPLTPage />
        },
        {
            path: '/tugas-belajar',
            element: <StudyPage />
        },
        {
            path: '/spmt-spmmj',
            element: <SPMTPage />
        },
        {
            path: '/presence/list',
            element: <PresencePage />
        },
        {
            path: '/presence/recap',
            element: <PresenceRecapPage />
        },
        {
            path: '/presence/leave',
            element: <PresenceLeavePage />
        },
        {
            path: '/salary/detail',
            element: <SalaryDetailPage />
        },
        {
            path: '/salary/submission',
            element: <SalarySubmissionPage />
        },
        {
            path: '/salary/basic',
            element: <SPMBasicPage />
        },
        {
            path: '/salary/meal-allowance',
            element: <SPMMealAllowancePage />
        },
        {
            path: '/salary/over-time',
            element: <SPMOverTimePage />
        },
        {
            path: '/salary/tpln',
            element: <SPMTPLNPage />
        },
        {
            path: '/salary/tunkin',
            element: <SPMTunkinPage />
        },
        {
            path: '/employee-list',
            element: <EmployeePage />
        },
        {
            path: '/salary/pph21',
            element: <CountPPh21Page />
        },
        {
            path: '/change-password',
            element: <ChangePasswordPage />
        },
        {
            path: '/faq',
            element: <FaqPage />
        },
        {
            path: '/setting/scheduler-setting',
            element: <SchedulerPage />
        },
        {
            path: '/sppt',
            element: <SPMTPage />
        },
        {
            path: '/setting/periode',
            element: <SKPPeriodePage />
        },
        {
            path: '/skp-data',
            element: <SKPListData />
        }
        
    ]
};

export default MainRoutes;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconSettings
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const setting = {
    id: 'setting',
    title: <FormattedMessage id="setting" />,
    type: 'group',
    children: [
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'master-plt',
                    title: <FormattedMessage id="master-plt" />,
                    type: 'item',
                    url: '/setting/master-plt'
                },
                {
                    id: 'master-uangmakan',
                    title: <FormattedMessage id="master-uangmakan" />,
                    type: 'item',
                    url: '/setting/master-uangmakan'
                },
                {
                    id: 'master-tunkin',
                    title: <FormattedMessage id="master-tunkin" />,
                    type: 'item',
                    url: '/setting/master-tunkin'
                },
                {
                    id: 'scheduler-setting',
                    title: <FormattedMessage id="scheduler-setting" />,
                    type: 'item',
                    url: '/setting/scheduler-setting'
                },
            ]
        }
    ]
};

export default setting;

import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';

export default function useUser() {
    const userContext = useContext(UserContext);
    return {
        user: userContext.user,
        token: userContext.token,
        setUser: userContext.setUser,
        setToken: userContext.setToken
    };
}
